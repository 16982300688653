import { createRouter, createWebHistory } from "vue-router";
import App from "../App.vue";
import LoginView from "../components/LoginView.vue";
import { createAuthGuard } from "@auth0/auth0-vue";

export default function createAppRouter(app) {
  const routes = [
    {
      path: "/login",
      name: "Login",
      component: LoginView,
      beforeEnter: createAuthGuard(app),
      props: true,
    },
    {
      path: "/",
      name: "Home",
      component: App,
    },
    {
      path: "/fact-checks/:topic/:slug",
      name: "FactCheck",
      component: App,
      props: true
    },
    {
      path: "/checks/:id",
      name: "LegacyCheck",
      component: App,
      props: true
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  return router;
}
