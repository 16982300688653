import { createApp } from "vue";
import App from "./App.vue";
import createAppRouter from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./styles/base.css";
import "./styles/animations.css";
import "./styles/responsive.css";
import { createAuth } from "./auth/auth";

const app = createApp(App);

createAuth(app);

const toastOptions = {
  position: "bottom-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
  rtl: false,
  toastClassName: "custom-toast",
  containerClassName: "custom-toast-container"
};

// Add global styles for toast
const style = document.createElement('style');
style.textContent = `
  .custom-toast.Vue-Toastification__toast--success {
    background-color: #4CAF50 !important;
    color: #FFFFFF !important;
  }
  .custom-toast .Vue-Toastification__icon {
    color: #FFFFFF !important;
  }
`;
document.head.appendChild(style);

const router = createAppRouter(app);
app.use(router);
app.use(Toast, toastOptions);
app.mount("#app");
