<template>
  <aside class="sidebar" :class="{ 'sidebar-closed': !isOpen }">
    <button class="sidebar-toggle mobile-sidebar-toggle" @click="$emit('toggle')" aria-label="Toggle Sidebar">
      <PanelLeftClose v-if="isOpen" />
      <PanelLeftOpen v-else />
    </button>
    <h2 class="sidebar-title">Saved Checks</h2>
    <div class="saved-chats">
      <template v-if="savedChats.today.length">
        <h3>Today</h3>
        <div v-for="chat in savedChats.today" :key="chat.id"
          :class="['saved-chat-item', { selected: currentChatId === chat.id }]"
          @click="$emit('loadChat', chat)">
          {{ chat.query }}
        </div>
      </template>

      <template v-if="savedChats.yesterday.length">
        <h3>Yesterday</h3>
        <div v-for="chat in savedChats.yesterday" :key="chat.id" 
          class="saved-chat-item" 
          @click="$emit('loadChat', chat)">
          {{ chat.query }}
        </div>
      </template>

      <template v-if="savedChats.older.length">
        <h3>Older</h3>
        <div v-for="chat in savedChats.older" :key="chat.id" 
          class="saved-chat-item" 
          @click="$emit('loadChat', chat)">
          {{ chat.query }}
        </div>
      </template>
    </div>
  </aside>
</template>

<script>
import { PanelLeftOpen, PanelLeftClose } from 'lucide-vue-next';

export default {
  components: {
    PanelLeftOpen,
    PanelLeftClose
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    savedChats: {
      type: Object,
      required: true
    },
    currentChatId: {
      type: [String, Number],
      default: null
    }
  },

  emits: ['toggle', 'loadChat']
};
</script>
