import { createAuth0 } from '@auth0/auth0-vue';
import { AUTH0_CONFIG } from './auth.config';

export function createAuth(app) {
  app.use(
    createAuth0({
      domain: AUTH0_CONFIG.domain,
      clientId: AUTH0_CONFIG.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
      }
    })
  );
}
