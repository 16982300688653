<template>
  <div class="content-wrapper" :class="{ 'moved-up': isSubmitted }">
    <QuestionModal
      :show="showQuestionModal"
      @close="showQuestionModal = false"
    />
    <DailyLimitModal
      :show="showDailyLimitModal"
      @close="showDailyLimitModal = false"
    />
    <ConfirmEmailModal
      :show="showConfirmEmailModal"
      @close="showConfirmEmailModal = false"
    />
    <div v-if="isLoadingCheck" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <div class="container">
      <h1 class="main-title">Check that shit!</h1>

      <div class="input-container">
        <div class="input-box">
          <div class="remaining-checks-wrapper">
            <div
              v-if="!isAuthenticated"
              class="remaining-checks"
              :class="{
                visible: false,
                'zero-checks': remainingChecks === 0,
              }"
            >
              {{ remainingChecks }}/1 daily checks remaining
            </div>
          </div>
          <div class="input-wrapper">
            <textarea
              v-model="inputValue"
              rows="4"
              class="textarea large-textarea"
              placeholder="What do you want to fact check?"
              :disabled="isSubmitted"
              @keydown.enter.prevent="
                (e) => {
                  if (!e.shiftKey && inputValue.length >= 5 && !isSubmitted) {
                    handleSubmit();
                  } else if (e.shiftKey) {
                    inputValue += '\n';
                  }
                }
              "
            ></textarea>
            <span
              v-if="inputValue.length >= 3 && inputValue.length < 30"
              class="helper-text"
              >Use shift + return for a new line</span
            >
          </div>
          <transition name="fade-scale">
            <button
              v-if="inputValue.length >= 5 && !isSubmitted"
              class="send-button"
              @click="handleSubmit"
            >
              &#10148;
            </button>
          </transition>
        </div>
        <transition name="fade-scale">
          <button
            v-if="isSubmitted && !isLoading"
            class="reset-button"
            @click="resetForm"
          >
            Check something else
          </button>
        </transition>
      </div>

      <transition name="fade-slide-down">
        <div v-if="isSubmitted" class="response-section">
          <div class="response-container">
            <div
              class="textarea large-textarea response-textarea no-cursor"
              :class="{ 'no-cursor': response.final_analysis }"
            >
              <div class="response-content">
                <div
                  v-show="!response.final_analysis && this.showShimmer"
                  class="shimmer-wrapper"
                >
                  <div class="shimmer-line"></div>
                  <div class="shimmer-line"></div>
                  <div class="shimmer-line"></div>
                  <div class="shimmer-line short"></div>
                </div>
                <div
                  v-show="displayedText"
                  class="typewriter-text"
                  v-html="displayedText"
                ></div>
              </div>
            </div>
          </div>

          <div
            v-show="!response.final_analysis && this.showLoadingBar"
            class="loading-section"
          >
            <div class="loading-bar">
              <div
                class="loading-progress"
                :style="{ width: `${loadingProgress}%` }"
              ></div>
            </div>
            <div class="loading-text">{{ currentLoadingText }}</div>
          </div>

          <transition name="fade">
            <div v-show="response.final_analysis" class="rating-section">
              <button
                class="rating-button thumbs-up"
                @click="submitRating(1)"
                aria-label="Thumbs Up"
              >
                <ThumbsUp :size="20" />
              </button>
              <button
                class="rating-button thumbs-down"
                @click="submitRating(-1)"
                aria-label="Thumbs Down"
              >
                <ThumbsDown :size="20" />
              </button>
              <button
                class="rating-button recheck"
                @click="recheckPrompt"
                aria-label="Check Again"
              >
                <RefreshCw :size="20" />
              </button>
              <button
                class="rating-button copy-link"
                @click="copyCheckLink"
                aria-label="Copy Link"
              >
                <Link :size="20" />
              </button>
            </div>
          </transition>

          <transition name="fade">
            <div
              v-if="response.sources && response.sources.length"
              class="sources-section"
            >
              <h3>Sources</h3>
              <ol class="sources-list">
                <li v-for="(source, index) in response.sources" :key="index">
                  <a :href="source" target="_blank" rel="noopener noreferrer">
                    {{ source }}
                  </a>
                </li>
              </ol>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.remaining-checks-wrapper {
  height: 1rem;
  margin-bottom: 0.25rem;
}

.remaining-checks {
  text-align: right;
  font-size: 0.7rem;
  color: rgba(128, 128, 128, 0.6);
  margin-right: 0.4rem;
  opacity: 0;
  transition: opacity 1s ease;
}

.remaining-checks.visible {
  opacity: 1;
}

.remaining-checks.zero-checks {
  color: lightcoral;
}

.input-wrapper {
  position: relative;
  position: relative;
}

.response-container {
  width: 100%;
  margin: 1rem 0;
}

.response-textarea {
  width: 100%;
  min-height: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: auto;
}

.typewriter-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>

<script>
import { ThumbsUp, ThumbsDown, RefreshCw, Link } from "lucide-vue-next";
import { useAuth0 } from "@auth0/auth0-vue";
import { useToast } from "vue-toastification";
import QuestionModal from "./QuestionModal.vue";
import DailyLimitModal from "./DailyLimitModal.vue";
import { API_BASE_URL } from "../config";
import {
  checkDailyLimit,
  incrementDailyChecks,
  getRemainingChecks,
  api,
} from "../services/tracking";
import { generateVerificationHash } from "../utils/hash";
import ConfirmEmailModal from "./ConfirmEmailModal.vue";

export default {
  components: {
    ThumbsUp,
    ThumbsDown,
    RefreshCw,
    Link,
    QuestionModal,
    DailyLimitModal,
    ConfirmEmailModal,
  },

  props: {
    topic: {
      type: String,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const toast = useToast();
    return { isAuthenticated, isAuth0Loading: isLoading, user, toast };
  },

  created() {},

  data() {
    return {
      inputValue: "",
      response: {
        final_analysis: "",
        sources: [],
        id: null,
      },
      isLoadingCheck: false,
      loadingProgress: 0,
      loadingTexts: [
        "Extracting claims...",
        "Calibrating bullshit detector...",
        "Exposing the Establishment...",
        "Smoking a cigarette...",
        "Almost done...",
      ],
      currentLoadingText: "Analyzing claims...",
      loadingInterval: null,
      textChangeInterval: null,
      displayedText: "",
      isTyping: false,
      typewriterSpeed: 0,
      isSubmitted: false,
      isLoading: false,
      showQuestionModal: false,
      hasShownModal: false,
      showShimmer: false,
      showLoadingBar: false,
      showDailyLimitModal: false,
      showConfirmEmailModal: false,
    };
  },

  watch: {
    inputValue(newValue) {
      if (
        !this.isAuth0Loading &&
        newValue &&
        newValue.length === 1 &&
        !this.checkId &&
        !this.canCheck
      ) {
        this.showDailyLimitModal = true;
        this.inputValue = "";
      }
    },
    "response.final_analysis": {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.startTypewriter(newValue);
        }
      },
    },
    topic: {
      immediate: true,
      handler(newTopic) {
        if (newTopic && this.slug) {
          this.loadCheck(newTopic, this.slug);
        }
      },
    },
    slug: {
      immediate: true,
      handler(newSlug) {
        if (newSlug && this.topic) {
          this.loadCheck(this.topic, newSlug);
        }
      },
    },
    id: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.loadLegacyCheck(newId);
        }
      },
    },
  },

  computed: {
    canCheck() {
      return checkDailyLimit(this.isAuthenticated);
    },
    remainingChecks() {
      return getRemainingChecks(this.isAuthenticated);
    },
  },

  methods: {
    async loadLegacyCheck(id) {
      this.isSubmitted = true;
      this.isLoadingCheck = true;
      this.showShimmer = false;
      this.showLoadingBar = false;
      try {
        const response = await api.get(`${API_BASE_URL}/checks/${id}`);
        this.inputValue = response.data.prompt || "";
        const finalAnalysis = response.data.response || "";
        this.response = {
          final_analysis: finalAnalysis,
          sources: response.data.sources || [],
          slug: response.data.slug,
          topic: response.data.topic,
        };
        this.displayedText = finalAnalysis;
        this.isTyping = true;
      } catch (error) {
        console.error("Failed to fetch check:", error);
      } finally {
        this.isLoadingCheck = false;
      }
    },

    async loadCheck(topic, slug) {
      this.isSubmitted = true;
      this.isLoadingCheck = true;
      this.showShimmer = false;
      this.showLoadingBar = false;
      try {
        const response = await api.get(`${API_BASE_URL}/checks`, {
          params: { topic, slug },
        });

        this.inputValue = response.data.prompt || "";
        const finalAnalysis = response.data.response || "";
        this.response = {
          final_analysis: finalAnalysis,
          sources: response.data.sources || [],
          slug: response.data.slug,
          topic: response.data.topic,
        };
        // Skip typewriter effect for saved checks
        this.displayedText = finalAnalysis;
        this.isTyping = true;
      } catch (error) {
        console.error("Failed to fetch check:", error);
      } finally {
        this.isLoadingCheck = false;
      }
    },

    resetForm() {
      // Fade out content first
      this.displayedText = "";
      this.response = {
        final_analysis: "",
        sources: [],
        topic: "",
        slug: "",
      };
      this.loadingProgress = 0;

      // Reset form state
      this.inputValue = "";
      this.isSubmitted = false;

      // Navigate while preserving origin parameter
      const urlParams = new URLSearchParams(window.location.search);
      const origin = urlParams.get("origin");
      const query = origin ? { origin } : {};
      this.$router.push({ path: "/", query });
    },

    async handleSubmit() {
      if (
        !this.isAuth0Loading &&
        this.isAuthenticated &&
        !this.user.email_verified
      ) {
        this.showConfirmEmailModal = true;
        return;
      }

      if (!this.isAuth0Loading && !this.canCheck) {
        this.showDailyLimitModal = true;
        return;
      }

      clearInterval(this.loadingInterval);
      clearInterval(this.textChangeInterval);

      // Reset states
      this.isSubmitted = true;
      this.loadingProgress = 0;
      this.isLoading = true;
      this.isTyping = false;
      this.displayedText = "";

      if (!this.hasShownModal) {
        setTimeout(() => {
          this.showQuestionModal = true;
          this.hasShownModal = true;
        }, 2000);
      }
      this.showShimmer = true;
      this.showLoadingBar = true;
      this.startLoading();
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const origin = urlParams.get("origin");

        const payload = {
          post_content: this.inputValue,
          ...(this.user?.email && { user_id: this.user.email }),
          ...(origin && { origin }),
        };

        const verificationHash = await generateVerificationHash(
          this.inputValue
        );
        const res = await api.post("/check", payload, {
          withCredentials: true,
          headers: {
            "X-Verification-Hash": verificationHash,
          },
        });
        this.response = res.data;
        this.isTyping = false;
        this.isLoading = false;

        // Track successful check
        if (!this.isAuthenticated) {
          incrementDailyChecks();
        }

        // Save check to localStorage
        const savedChats = JSON.parse(
          localStorage.getItem("savedChats") || "[]"
        );
        const newChat = {
          id: Date.now(),
          datetime: new Date().toISOString(),
          query: this.inputValue,
          response: res.data,
        };
        savedChats.unshift(newChat);
        localStorage.setItem("savedChats", JSON.stringify(savedChats));
        this.$emit("chatSaved", savedChats);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.response = {
            final_analysis: error.response.data.error,
            sources: [],
          };
        } else {
          this.response = {
            final_analysis: "Error: " + error.message,
            sources: [],
          };
        }
      }
    },

    startLoading() {
      let textIndex = 0;
      const loadingDuration = 17000;
      const progressInterval = 100;
      const progressIncrement = (100 * progressInterval) / loadingDuration;

      this.loadingInterval = setInterval(() => {
        this.loadingProgress = Math.min(
          this.loadingProgress + progressIncrement,
          100
        );
        if (this.loadingProgress >= 100) {
          clearInterval(this.loadingInterval);
          clearInterval(this.textChangeInterval);
        }
      }, progressInterval);

      this.textChangeInterval = setInterval(() => {
        textIndex = (textIndex + 1) % this.loadingTexts.length;
        this.currentLoadingText = this.loadingTexts[textIndex];
      }, 4000);
    },

    async submitRating(rating) {
      try {
        const checkId = this.response.id;
        if (!checkId) {
          console.error("No check ID found");
          return;
        }
        await api.post(`${API_BASE_URL}/checks/${checkId}/feedback`, {
          rating: rating,
        });
      } catch (error) {
        console.error("Failed to submit rating:", error);
      }
    },

    async recheckPrompt() {
      // Reset response but keep the input value
      const currentInput = this.inputValue;
      this.resetForm();
      this.inputValue = currentInput;
      await this.handleSubmit();
    },

    async copyCheckLink() {
      console.log(this.response);
      console.log("adas");
      if (this.response.topic && this.response.slug) {
        const checkUrl = `${window.location.origin}/fact-checks/${this.response.topic}/${this.response.slug}`;
        try {
          await navigator.clipboard.writeText(checkUrl);
          this.toast.success("Link copied!", {
            timeout: 2000,
            position: "bottom-center",
            hideProgressBar: true,
          });
        } catch (err) {
          console.error("Failed to copy link:", err);
        }
      }
    },

    async startTypewriter(text) {
      if (this.isTyping) return;
      this.isTyping = true;
      this.displayedText = "";

      for (let i = 0; i < text.length; i++) {
        await new Promise((resolve) =>
          setTimeout(resolve, this.typewriterSpeed)
        );
        this.displayedText += text[i];
      }

      this.isTyping = false;
    },
  },

  beforeUnmount() {
    clearInterval(this.loadingInterval);
    clearInterval(this.textChangeInterval);
  },
};
</script>
