import axios from "axios";
import { ref } from "vue";
import { API_BASE_URL } from "../config";

const lastCheckDate = ref(localStorage.getItem("lastCheckDate"));

// Create an axios instance with credentials enabled
export const api = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
});

export const trackVisit = async (origin) => {
  await trackAction("visit", origin);
};

export const trackAction = async (action, origin = null) => {
  try {
    await api.post("/track", {
      action,
      ...(origin && { origin }),
    });
  } catch (error) {
    console.error(`Failed to track ${action}:`, error);
    throw error;
  }
};
export const checkDailyLimit = (isAuthenticated) => {
  // eslint-disable-next-line no-unreachable
  if (isAuthenticated) {
    return true;
  }

  const today = new Date().toISOString().split("T")[0];

  // Allow check if no previous check today
  return lastCheckDate.value !== today;
};

export const getRemainingChecks = (isAuthenticated) => {
  if (isAuthenticated) {
    return "∞";
  }
  const today = new Date().toISOString().split("T")[0];
  return lastCheckDate.value === today ? 0 : 1;
};

export const incrementDailyChecks = () => {
  const today = new Date().toISOString().split("T")[0];
  localStorage.setItem("lastCheckDate", today);
  lastCheckDate.value = today;
};
