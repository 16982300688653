<template>
  <div v-if="shouldShow" class="banner">
    <div class="banner-content">
      <div class="text-content">
        <h2>Quick question, checker!</h2>
        <span
          >If you could pick one social media platform/source to fact-check with
          <strong>one click</strong>, which would it be?</span
        >
      </div>
      <div class="radio-container">
        <label class="radio-label">
          <input
            type="radio"
            v-model="selectedPlatform"
            :value="1"
            name="platform"
          />
          Reddit
        </label>
        <label class="radio-label">
          <input
            type="radio"
            v-model="selectedPlatform"
            :value="2"
            name="platform"
          />
          Twitter/X
        </label>
        <label class="radio-label">
          <input
            type="radio"
            v-model="selectedPlatform"
            :value="3"
            name="platform"
          />
          News sites
        </label>
      </div>
      <button
        class="banner-button submit-button"
        @click="handleResponse"
        :disabled="!selectedPlatform"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

import { API_BASE_URL } from "../config";

export default {
  name: "QuestionModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldShow() {
      return this.show && !localStorage.getItem("preferredPlatformQuestionShown");
    },
  },
  data() {
    return {
      hasResponded: false,
      selectedPlatform: null,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async handleResponse() {
      if (this.hasResponded || !this.selectedPlatform) return;

      try {
        this.hasResponded = true;
        this.toast.success("Thank you for your response!", {
          timeout: 2000,
          position: "bottom-center",
          hideProgressBar: true,
        });
        await axios.post(`${API_BASE_URL}/questions`, {
          slug: "preferred_platform",
          reply: this.selectedPlatform,
        });
      } catch (error) {
        console.error("Failed to submit response:", error);
      }
      localStorage.setItem("preferredPlatformQuestionShown", "true");
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  font-family: "Open Sans", sans-serif;
  animation: slideUp 0.3s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.banner-content {
  max-width: 80%;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.text-content {
  flex: 1;
  max-width: 65%;
}

.text-content h2 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

.text-content span {
  font-size: 0.9rem;
  color: #666;
}

.button-container {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.banner-button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 20px;
  background-color: lightcoral;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  transition: all 0.2s ease;
}

.banner-button:hover {
  background-color: #e06666;
  transform: translateY(-1px);
}

.radio-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 2rem auto;
  padding: 0 1rem;
}
.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.submit-button {
  margin: 0 auto;
  display: block;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .banner-content {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .banner-content {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .radio-container {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem auto
  }

  .text-content {
    text-align: center;
  }

  .button-container {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 365px) {
  .text-content {
    max-width: 100%;
  }
}
</style>
