<template>
  <div class="terms-modal-overlay" @click="$emit('update:show', false)">
    <div class="terms-modal-content" @click.stop>
      <button class="close-button" @click="$emit('update:show', false)">
        &times;
      </button>
      <h2>Terms and Conditions</h2>
      <div class="terms-content">
        <h3>Introduction</h3>
        <p>
          Welcome to our fact-checking tool CheckThatShit.com ("the Tool").
          These Terms and Conditions ("Terms") govern your use of our web-based
          fact-checking service. By using the Tool, you agree to be bound by
          these Terms.
        </p>

        <h3>Account Management</h3>
        <p>
          You may create an account using email or Google authentication. For
          email-based accounts, we collect your email address. For Google
          authentication, we collect your email address, first name, and last
          name. You have the right to delete your account at any time, which
          will remove all associated data from our systems.
        </p>

        <h3>The Service</h3>
        <p>
          The Tool is a free web-based fact-checking service that provides
          information based on publicly available internet sources. We do not
          generate original content or personal opinions; all responses are
          based on existing online sources.
        </p>

        <h3>Usage Limits</h3>
        <p>
          Non-authenticated users are limited to 1 fact-checking request per
          day. Authenticated users have access to unlimited fact-checking
          requests. To ensure fair access for all users, the Tool is limited to
          3 fact-checking requests per minute per user. We reserve the right to
          modify these limits at any time to ensure service quality and
          availability.
        </p>

        <h3>User Rights and Responsibilities</h3>
        <p>
          You agree to use the Tool for legitimate fact-checking purposes, not
          attempt to circumvent the rate limits, not use automated means to
          access the Tool, and not interfere with the Tool's operation.
        </p>

        <h3>Sharing Rights</h3>
        <p>
          You are free to share, copy, and redistribute the fact-checking
          results provided by the Tool, use the results for personal,
          educational, or professional purposes, and quote or reference the
          Tool's responses with appropriate attribution.
        </p>

        <h3>Disclaimers</h3>
        <p>
          The Tool relies solely on publicly available internet sources. We do
          not guarantee the accuracy, completeness, or timeliness of the
          information provided. Users should independently verify critical
          information. The Tool's responses should not be considered as
          professional advice.
        </p>

        <h3>Service Availability</h3>
        <p>
          The Tool is provided "as is" and "as available". We do not guarantee
          uninterrupted or error-free operation. The service may be unavailable
          at times for maintenance or technical issues. We reserve the right to
          modify or discontinue the service at any time.
        </p>

        <h3>Intellectual Property</h3>
        <p>
          The Tool's code, design, and functionality are protected by applicable
          intellectual property laws. The fact-checking responses generated by
          the Tool may be freely shared under attribution.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          This Tool is provided by an individual for public benefit, not by a
          legal entity. The creator(s) accept no personal liability for the
          Tool's use or misuse, make no warranties or guarantees about the
          service, and are not responsible for decisions made based on the
          Tool's output.
        </p>

        <h3>Damages</h3>
        <p>
          Under no circumstances shall the Tool's creator(s) be liable for any
          direct, indirect, special, incidental, or consequential damages
          arising from use or inability to use the Tool, reliance on information
          provided by the Tool, or errors or omissions in fact-checking results.
        </p>

        <h3>Changes to Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. Continued use
          of the Tool after changes constitutes acceptance of the new Terms.
        </p>

        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed by general principles of fairness and
          good faith, with due regard to the non-commercial and public benefit
          nature of the Tool.
        </p>

        <h3>Contact</h3>
        <p>
          For questions about these Terms or the Tool's operation, please
          contact
          <a href="mailto:admin@checkthatshit.com">admin@checkthatshit.com</a>.
        </p>

        <h3>Severability</h3>
        <p>
          If any provision of these Terms is found to be unenforceable, the
          remaining provisions will remain in effect.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:show"],
};
</script>

<style scoped>
.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.terms-modal-content {
  background: #fcf5e6;
  width: 70%;
  max-width: 800px;
  height: 90%;
  border-radius: 12px;
  padding: 3rem;
  position: relative;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.terms-content {
  margin-top: 1rem;
}

.terms-content p {
  line-height: 1.8;
  text-align: left;
  font-size: 1rem;
  padding: 0 1rem;
}

.terms-content h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: left;
  padding: 0 1rem;
}

.terms-content h3 {
  margin-top: 2rem;
}

.terms-content ul {
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  list-style-type: disc;
}

@media (max-width: 480px) {
  .terms-content {
    font-size: 1rem;
  }
  .terms-modal-content {
    width: 100%;
    padding: 1rem;
  }
}
</style>
