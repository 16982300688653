<template>
    <div class="modal-overlay" @click="$emit('update:show', false)">
        <div class="modal-content" @click.stop>
            <span class="close" @click="$emit('update:show', false)">&times;</span>
            <h2>Feedback</h2>
            <div class="stars">
                <span v-for="star in 5" :key="star" class="star" :class="{ active: star <= rating }"
                    @click="rating = star">
                    &#9733;
                </span>
            </div>
            <textarea v-model="feedbackText" rows="3" class="textarea small-textarea"
                placeholder="Write your feedback here..."></textarea>
            <button class="send-button modal-send" @click="handleSubmit">Send</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },

    emits: ['update:show', 'submit'],

    data() {
        return {
            rating: 0,
            feedbackText: ""
        };
    },

    methods: {
        handleSubmit() {
            this.$emit('submit', {
                score: this.rating,
                comment: this.feedbackText
            });
            this.$emit('update:show', false);
            this.rating = 0;
            this.feedbackText = "";
        }
    }
};
</script>

<style scoped>
/* Modal Styles */
.modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1020;
    padding: clamp(1rem, 3vw, 2rem);
}

.modal-content {
    background: #ffffff;
    padding: clamp(1.5rem, 4vw, 2.5rem);
    border-radius: clamp(0.5rem, 2vw, 1rem);
    width: min(90%, 30rem);
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
    font-weight: 400;
    color: #333;
}

.modal-content .send-button {
    position: static;
    width: 100%;
    border-radius: 2rem;
    height: auto;
    padding: 0.8rem;
    margin-top: 1rem;
}

@media (max-width: 480px) {
    .modal-content {
        padding: 1.5rem;
    }
}
</style>
