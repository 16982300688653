<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-button" @click="$emit('close')">&times;</button>
      <h2>One last thing...</h2>
      <p>We also need you to confirm your email address.</p>
      <p>If you have already done so, please refresh the page and try again.</p>
      <div class="modal-buttons">
        <button class="primary-button" @click="$emit('close')">Okay</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmEmailModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: clamp(0.5rem, 2vw, 1rem);
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
}

.modal-content h2 {
  color: #333;
  font-weight: 400;
}

.modal-content p {
  color: #333;
}

.modal-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.primary-button {
  background: lightcoral;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  width: 60%;
  height: auto;
  border-radius: 2rem;
  cursor: pointer;
  padding: 0.8rem;
}

.primary-button:hover {
  background: #e57373;
}
</style>
